<template>
  <div>
    <!-- <v-btn @click="$router.push(`/newYear/${id}`)" v-on="on" v-bind="attrs" color="green" class="white--text" style="position: relative; right: 800px;">
                <v-icon size="20">mdi-plus</v-icon>
                التسجيل في العام الجديد 
    </v-btn> -->
    <template> </template>
    <app-base-table
      :tableOptions="tableOptions"
      @re-fetch-data="fetchData"
      @re-fetch-paginated-data="fetchData($event)"
      :enableDelete="false"
      :flaggedItems="flaggedItemsData"
      :displaySettings="false"
    >
    </app-base-table>
    <v-row>
      <v-col md="9" class="mr-2"></v-col>
    <v-col md="2" class="mr-2">
          <v-switch
          @change="goToAll"    
          v-model="is_all"
          color="green"
          :label="$t('Show All')"
        ></v-switch>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      is_all: false,
      paginated: true,
      flaggedItemsData: [
        "account_id",
        "student",
        "id",
        "number_transfer_document",
        // "public_record_number"
      ],
    };
  },
  props: {
    // either grade_id or edu-class_id
    id: {
      type: [String, Number],
    },
    // either grade or edu-class
    idType: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      tableOptions: "students/getTableOptions",
    }),
  },
  methods: {
    ...mapActions({
      fetchStudents: "students/fetchStudents",
    }),
    goToAll(){
      if(this.is_all) {
        this.paginated = false;
      } else { this.paginated = true; }
      this.fetchData();
    },
    fetchData(page) {
      let params = {
        page,
        paginated: this.paginated,
      };
      if (this.idType == "grade") {
        params.grade_id = this.id;
      }
      if (this.idType == "edu-class") {
        params.edu_class_id = this.id;
      }
      this.fetchStudents(params);
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>

<style>
</style>