<template>
  <v-dialog class="dialog">
    <template v-slot:activator="{ on }">
      <div v-on="on" class="align-self-center">
        <v-btn color="success" :loading="btnLoading" @click="clearData()" class="login__btn">{{ $t('add now') }}</v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="d-flex justify-center card-title__heading">
        <h2>{{ $t('add student to new year') }}</h2>
      </v-card-title>
      <v-row>
        <v-col md="5">
          <v-autocomplete hide-details v-model="year" outlined dense :items="years.educational_years"
            :label="$t('year')" item-text="name" item-value="value"></v-autocomplete>
        </v-col>
        <v-col md="5">
          <v-autocomplete :disabled="!year" hide-details v-model="term" outlined dense :items="terms"
            @click="selectTerms" :label="$t('term')" item-text="name" item-value="value"></v-autocomplete>
        </v-col>
        <v-col md="5">
          <v-autocomplete :disabled="!term" hide-details v-model="class_name" outlined dense :items="classes"
            @click="selectClass" :label="$t('class')" item-text="name" item-value="value"></v-autocomplete>
        </v-col>
        <v-col md="5">
          <v-autocomplete :disabled="!class_name" hide-details v-model="grade" outlined dense :items="grades"
            @click="selectGrade" :label="$t('education class name')" item-text="name"
            item-value="value"></v-autocomplete>
        </v-col>
      </v-row>
      <v-card-actions class="justify-center">
        <v-btn @click="submit" :loading="btnLoading" color="green" class="white--text"
          :disabled="!year || !term || !class_name || !grade">{{ $t('add') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  props: ["students"],
  data() {
    return {
      btnLoading: false,
      classes: [],
      classes_id: [],
      years: [],
      terms: [],
      terms_id: [],
      grades: [],
      grades_id: [],
      year: null,
      term: null,
      class_name: null,
      grade: null,
      info_id: {
        years_id: null,
        term_id: null,
        class_id: null,
        edu_class_id: null,
      },
    };
  },
  methods: { 
    clearData(){
      this.year = null;
      this.term = null;
      this.class_name = null;
      this.grade = null;
    },
    selectTerms() {
      this.classes = [];
      this.classes_id = [];
      this.class_name = null;
      this.years.educational_years.forEach((year, index) => {
        if (this.year == year.name) {
          let params = {
            educational_year_id: year.id,
          };
          axios
            .get(`/term`, {
              params,
            })
            .then((response) =>
              response.data.terms.forEach((term, index) => {
                this.terms.push(term.name);
                this.terms_id.push(term.id);
              })
            );
        }
      });
    },
    selectClass() {
      this.grades = [];
      this.grades_id = [];
      this.grade = null;
      this.gra;
      let index = this.terms.indexOf(this.term);
      this.info_id.term_id = this.terms_id[index];
      let params = {
        term_id: this.info_id.term_id,
        ignore_term: true,
      };
      axios
        .get(`/grade`, {
          params,
        })
        .then((response) =>
          response.data.grades.forEach((grade, index) => {
            this.classes.push(grade.name);
            this.classes_id.push(grade.id);
          })
        );
    },
    selectGrade() {
      let index = this.classes.indexOf(this.class_name);
      this.info_id.class_id = this.classes_id[index];
      console.log(this.info_id.class_id);
      let params = {
        grade_id: this.info_id.class_id,
        ignore_term: true,
      };
      axios
        .get(`/edu-class`, {
          params,
        })
        .then((response) =>
          response.data.classes.forEach((item, index) => {
            this.grades.push(item.name);
            this.grades_id.push(item.id);
          })
        );
    },
    submit() {
      this.btnLoading = true;
      let index = this.grades.indexOf(this.grade);
      this.info_id.edu_class_id = this.grades_id[index];
      console.log("student list", this.students);
      console.log("edu_class_id  ", this.info_id.edu_class_id);
      this.btnLoading = false;

      axios.post('educational-year/transfer?ignore_term=true', {
        registration_records: this.students,
        edu_class_id: this.info_id.edu_class_id
      }).then(response => {
        if (response.status == 200) {
          this.$Notifications(
            this.$t('add success'),
            { rtl: true, timeout: 2000 },
            "succes"
          );
        } error => {
          this.$Notifications(
            this.$t('some thing wrong'),
            { rtl: true, timeout: 2000 },
            "error"
          );
        }
      })
    },
    checkInput() {
      if (this.year & this.term & this.class_name & this.edu_class_id) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    axios
      .get(`/educational-year`)
      .then((response) => (this.years = response.data));
  },
  computed: {},
};
</script>

<style scoped>
v-card {
  /* height: 700px;
  width: 500px; */
}
</style>