<template>
  <div class="table__spinnerWrapper" v-if="loading">
    <app-spinner></app-spinner>
  </div>
  <div v-else>
    <v-container id="regular-tables" fluid tag="section" class="px-15">
      <v-col md="2">
        <add-class-info :students="selected"></add-class-info>
      </v-col>

      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
            <th>
              {{ $t('select all') }}
              <input v-model="selected_all" @click="selectAll" type="checkbox" />
            </th>
          </tr>
          <tr v-for="(student, index) in students" :key="index">
            <td>{{ student.id }}</td>
            <td>{{ student.student.first_name }}</td>
            <td>{{ student.student.last_name }}</td>
            <td>{{ student.student.father_name }}</td>
            <td>{{ student.student.mother_name }}</td>
            <td v-if="student.student.type == 'new'">{{ $t('new') }}</td>
            <td v-else>{{ $t('Transported') }}</td>
            <td>
              <input :value="student.id" v-model="selected" type="checkbox" />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import students from "../../shared-eduClass&grade/students.vue";
import AddClassInfo from "./addClassInfo.vue";
export default {
  components: {
    appStudents: students,
    AddClassInfo,
  },
  data() {
    return {
      selected_all: false,
      selected: [],
      students: [],
      eduClassId: this.$route.params.id,
      tableHeaders: [
        "id",
        "user name",
        "last name",
        "father name",
        "mother name",
        "type",
      ],
      loading: true,
    };
  },
  mounted() {
    let params = {
      size: 50,
      page: 1,
      edu_class_id: this.eduClassId,
    };
    axios
      .get(`/registration-record`, {
        params,
      })
      .then(
        (response) => (
          console.log(response.data),
          (this.students = response.data.data),
          (this.loading = false)
        )
      );
  },

  methods: {
    selectAll() {
      this.selected = [];
      if (!this.selected_all) {
        this.students.forEach((student, index) => {
          this.selected.push(student.id);
        });
      }
    },
  },
};
</script>

<style>

</style>